.container-home {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  height: auto;
  min-height: 90vh;
  margin: auto;
  width: 70vw;
}

.contnuto {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  text-align: justify;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.info {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.come_fare {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
}
.come_fare p {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 1.6rem;
}
.container-home .ingr {
  width: 96%;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 5px auto;
}
.qr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  box-sizing: border-box;
  width: 100%;
}
.testo-uso {
  width: 100%;
}
.testo_qr {
  display: flex;
  flex-direction: row;
  width: 80%;
  height: auto;
  align-items: center;
  justify-content: center;
  font-variant: small-caps;
  padding: 5px auto;
  text-align: center;
}
.raccolta{
  width: 100%;
  margin: 20px 0;
}
@media all and (max-width: 600px) {
  .container-home {
    width: 95%;
  }
}
