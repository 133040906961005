.nav {
  display: flex;
  flex-direction: column;
  width: 220px;
  position: fixed;
  background-color: #ffffff;
  top: 0px;
  border-right: 1px solid #c4c4c4;
  font-weight: bold;
  overflow: visible;
  bottom: 0;
  z-index: 1000;
  left: -221px;
  transition: all ease-in-out .4s;
}
.menu_icon_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 1000;
}
.menu_icon {
  display: flex;
  flex-direction: column;
  width: 36px;
  height: 36px;
  position: absolute;
  padding: 5px;
  box-sizing: border-box;
  top: 5px;
  right: -38px;
  z-index: 1001 !important;
}
.menu_icon span{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 2.3px;
  background-color: #ffffff;
  margin-top: 6px;
  border-radius: 3px;
}
.menu_icon span:first-child{
  margin-top: 2px;
}
.menu_icon_container img {
  width: 50%;
  margin: 0 auto 10px auto;
  padding: 10px 0;
  box-sizing: border-box;
}
.nav h2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
}
.content-nav {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  width: 100%;
  height: auto;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.content-nav::-webkit-scrollbar {
  display: none;
}
.content-nav a {
  line-height: 1.5em;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.1em;
  color: rgba(#fff, 0.35);
  color: rgb(84, 94, 111);
  padding: 11px 10px;
  font-variant: small-caps;
  font-size: 0.75em;
}
.content-nav.active a {
  color: white;
}
.content-nav:not(.active)::after {
  opacity: 0.2;
}
.content-nav:not(.active):hover a {
  color: rgba(#fff, 0.75);
}
.content-nav::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.2em;
  background: rgb(155, 155, 155);
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to right, #aa9ad3, #b27f9f);
}
.line {
  border-bottom: 1px solid #9e9e9e;
}
.logout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 5px;
  box-sizing: border-box;
  color: #7600dc;
}
@media screen and (max-width: 600px) {
  .menu_icon{
    top: 12px;
  }
}