@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");

.radio-label {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 50%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  border: 1px solid #c1c1c1;
  margin: 2px 0;
  box-sizing: border-box;
}
.radio-input{
  margin: 0 5px 0 0;
}
.radio-text{
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  padding: 5px 0;
  font-size: 18px;
}
.active-choise{
  border-color: #1872a3;
}

@media screen and (max-width: 799px) {
  .radio-label{
    width: 100%;
    max-width: 100%;
  }
}