.container-topbar {
  display: flex;
  position: fixed;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  background-color: #246cbe;
  box-sizing: border-box;
  left: 0;
  right: 0;
  top: 0;
  height: auto;
  min-height: 45px;
  z-index: 100;
  transition: all ease-in-out .4s;
}
.gruppo_icone {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  align-items: center;
  justify-content: flex-start;
}
.gruppo_left{
  margin-left: 34px;
}
.menu-topbar {
  display: flex;
  flex-direction: row;
  font-size: 0.9em;
  margin: auto 5px;
  padding: 5px;
  align-content: center;
  justify-content: center;
  color: #ffffff;
  text-decoration: none;
  width: auto;
}
.menu-topbar label {
  display: flex;
  flex-direction: row;
  width: auto;
  height: 25px;
  align-items: center;
  justify-content: center;
}
.menu-topbar span{
  margin: 0 auto;
}

@media all and (max-width: 600px){
  .menu-topbar{
    flex-direction: column;
    font-size: .8em;
    margin: auto 0;
    justify-content: center;
  }
  .menu-topbar label{
    font-size: 13px;
    text-align: center;
  }
  .horizontal{
    flex-direction: row;
  }
}
