.container-fornoinfo {
  display: flex;
  flex-direction: row;
  width: 90%;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  flex-wrap: wrap;
  margin: 10px auto;
}
.ingr {
  display: flex;
  flex-direction: row;
  width: 250px;
  height: auto;
  padding: 8px 10px;
  margin: 2px;
  border: .5px solid #8a8a8a;
  border-radius: 2px;
  align-self: flex-start;
}
.ingr input:checked .ingr{
  border-color: #1872a3;
  background-color: #8a8a8a;
}