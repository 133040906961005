.baseinfo-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  margin: 10px 0;
}
.field {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 500px;
  align-items: flex-start;
  justify-content: flex-start;
}
.field__label-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  margin: auto;
}
.pizza-btn-container {
  display: flex;
  flex-direction: row;
  width: 500px;
  height: auto;
  align-items: center;
  justify-content: space-between;
}
.pizza-btn-container .btn{
    margin: 0;
}
@media screen and (max-width: 799px) {
  .baseinfo-container{
    width: 95%;
    margin: auto;
  }
  .field{
    min-width: 0;
  }
  .pizza-btn-container{
    width: 90%;
  }
}