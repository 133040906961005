.card-personalizzami{
  display: flex;
  flex-direction: column;
  width: 300px;
  
  margin:15px;
  
  border-style: solid;
    border-width: 1px;
    padding :10px
  

}
.titolo-personalizzami{
  font-size: 1.4em;
  margin-bottom: 9px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  width: 98%;
  margin: 0 auto;
  height: auto;
  word-wrap: break-word;
}
.image-personalizzami{
  height: 200px;
  object-fit: cover;
 
}


.pulsanti-personalizzami{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.conenitore-info-ersonalizzami{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgb(96, 94, 94);
  margin: 1em 0;
  padding: 0;
}

@media screen and (max-width: 600px) {
  .card-personalizzami{
    width: 100%;
  }
}