@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap");

.table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  align-items: flex-start;
  justify-content: flex-start;
}
section {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  display: table;
  width: 98%;
  margin: 0 auto;
}
.table {
  display: table;
  width: 100%;
}
.header {
  border-style: solid;
  border-width: 10px;
  background-color: #1b4389;
  color: #ffffff;
}
.col {
  padding: 10px;
  border-bottom: solid;
  border-width: 0.1rem;
  border-color: rgb(183, 211, 255);
}

section > * {
  display: table-row;
}

section .col {
  display: table-cell;
}
.row {
}
.int {
  font-weight: bold;
  text-transform: uppercase;
}
