.container-layout {
  height: 100vh;
}
.subcontainer-layout {
  display: flex;
  height: 100%;
}
.corpo {
  overflow-y: auto;
  position: absolute;
  left: 0;
  top: 0;
  left: 0;
  width: 100vw;
  height: auto;
  min-height: auto;
  margin-top: 45px;
  transition: all ease-in-out 0.4s;
}
@media all and (max-width: 600px) {
  .corpo {
    width: 100vw;
    left: auto;
    top: 10px;
  }
}
